import React from "react";
import "./App.scss";
import Navbar from "./components/Navbar/Navbar";
import {
  Contact,
  Demo,
  Home,
  MessageBoard,
  Profile,
  Roadmap,
  SignIn,
  Support,
} from "./pages";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "firebase/auth";
import fire from "./firebase";
import PasswordReset from "./pages/PasswordReset";
import Footer from "./components/Footer/Footer";

function App() {
  const logo = document.querySelectorAll("#logo path");
  console.log(logo);

  for (let i = 0; i < logo.length; i++) {
    console.log(`Letter ${i} is ${logo[i].getTotalLength()}`);
  }

  console.log("app user: ", fire.auth().currentUser);

  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/demo" exact component={Demo} />
          <Route path="/reset" exact component={PasswordReset} />
          <Route path="/profile" exact component={Profile} />
          <Route path="/board" exact component={MessageBoard} />
          <Route path="/roadmap" exact component={Roadmap} />
          <Route path="/signin" exact component={SignIn} />
          <Route path="/support" exact component={Support} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
