import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export default function ContactModal(props) {
  const [redirect, setRedirect] = useState(false);

  console.log("redirect", redirect);

  if (redirect) {
    return <Redirect to="/" />;
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onExit={() => {
        setRedirect(true);
      }}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <h6>Contact Form Entry</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 style={{ color: "green" }}>Success!</h4>
        <p>
          Thank you for your interest. Your contact details have been saved.
          Watch your inbox for further communications.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <FontAwesomeIcon
          id="contact-close"
          icon={faTimes}
          onClick={props.onHide}
          style={{
            marginLeft: "95%",
            fontSize: "36px",
          }}
          title="Sign in to access this content"
        />
      </Modal.Footer>
    </Modal>
  );
}
