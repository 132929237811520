import React from "react";
import { Parallax, ParallaxLayer } from "react-spring/renderprops-addons";
import { Redirect } from "react-router-dom";
import "firebase/auth";
import fire from "../firebase";

const Roadmap = () => {
  // const [user, setUser] = useState();
  let parallax;

  // const authListener = () => {
  //   fire.auth().onAuthStateChanged((user) => {
  //     if (user) {
  //       setUser(user);
  //     } else {
  //       setUser("");
  //     }
  //   });
  // };

  console.log("roadmap currentUser fire: ", fire.auth().currentUser);

  if (fire.auth().currentUser != null) {
    return (
      <div className="roadmap-container">
        <Parallax ref={(ref) => (parallax = ref)} pages={7}>
          <ParallaxLayer
            factor={1}
            offset={1}
            speed={0}
            style={{ backgroundColor: "#000000", backgroundSize: "cover" }}
          />
          <ParallaxLayer
            factor={1}
            offset={3}
            speed={0}
            style={{ backgroundColor: "#000000", backgroundSize: "cover" }}
          />
          <ParallaxLayer
            factor={1}
            offset={5}
            speed={0}
            style={{ backgroundColor: "#000000", backgroundSize: "cover" }}
          />

          <ParallaxLayer
            offset={0}
            speed={0}
            style={{
              alignItems: "center",
              backgroundImage:
                'url("https://images.unsplash.com/photo-1518623001395-125242310d0c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80")',
              backgroundSize: "cover",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ color: "#ffffff", width: "70%" }}>
              <h1 style={{ fontSize: "4em", marginBottom: "1em" }}>ROADMAP</h1>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse
                libero quam sapiente repellendus minima repellat quibusdam, est
                amet dignissimos vitae? Eveniet saepe est aliquid ullam modi,
                veniam quaerat id nobis.
              </p>
            </div>
          </ParallaxLayer>

          <ParallaxLayer
            offset={1}
            speed={0}
            onClick={() => parallax.scrollTo(2)}
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <h1 style={{ color: "#5FB88C", fontSize: "10em" }}>DISCOVER</h1>
          </ParallaxLayer>

          <ParallaxLayer
            offset={1.35}
            speed={0.3}
            style={{ opacity: 0.4, pointerEvents: "none" }}
          >
            <img
              alt="tv"
              src=" https://www.cable-tv.com/assets/default/images/heroes/device-tv.png"
              style={{ marginLeft: "76%", pointerEvents: "none", width: "20%" }}
            />
            <img
              alt="Fargo"
              src="https://c-sf.smule.com/rs-s77/arr/68/e6/73ff18a9-2cbd-43e4-a275-6c0ac4e20a34_256.jpg"
              style={{
                position: "relative",
                bottom: "14em",
                marginLeft: "76.5%",
                pointerEvents: "none",
                height: "19.5%",
                width: "18%",
              }}
            />
          </ParallaxLayer>

          <ParallaxLayer offset={1.35} speed={1} style={{ opacity: 0.4 }}>
            <img
              alt="Pink Floyd"
              src="https://i.pinimg.com/originals/6c/8c/d8/6c8cd8390a5e682b774edc225e1fc678.jpg"
              style={{
                display: "block",
                marginLeft: "60%",
                pointerEvents: "none",
                width: "8%",
              }}
            />
          </ParallaxLayer>

          <ParallaxLayer offset={1.4} speed={0.2} style={{ opacity: 0.4 }}>
            <img
              alt="The Institute"
              src="https://i.pinimg.com/originals/4e/52/e5/4e52e5dbadc05be3acdde1a58309596f.png"
              style={{
                display: "block",
                marginLeft: "7%",
                pointerEvents: "none",
                width: "10%",
              }}
            />
          </ParallaxLayer>

          <ParallaxLayer offset={1.45} speed={0.8} style={{ opacity: 0.4 }}>
            <img
              alt="Monopoly"
              src="https://images-na.ssl-images-amazon.com/images/I/81btrHKgO0L._AC_SX425_.jpg"
              style={{
                position: "relative",
                bottom: "15em",
                display: "block",
                marginLeft: "22%",
                pointerEvents: "none",
                width: "10%",
              }}
            />
          </ParallaxLayer>

          <ParallaxLayer offset={1.6} speed={0.1} style={{ opacity: 0.4 }}>
            <img
              alt="art"
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTEZOAyP6U_ht6goUuqFfJC7Bz_UWbz406EcryFoW8RE-0M45Gac3GsyWaGQxXgOxnDYDFOvDh_&usqp=CAc"
              style={{
                display: "block",
                marginLeft: "25%",
                pointerEvents: "none",
                width: "20%",
              }}
            />
          </ParallaxLayer>

          <ParallaxLayer offset={1.75} speed={0.3} style={{ opacity: 0.4 }}>
            <img
              alt="drone"
              src="https://store-guides2.djicdn.com/guides/wp-content/uploads/2018/06/mavic-air-red-min-2.png"
              style={{
                display: "block",
                marginLeft: "59%",
                pointerEvents: "none",
                width: "17%",
              }}
            />
          </ParallaxLayer>

          <ParallaxLayer
            offset={2.45}
            speed={0}
            style={{
              alignItems: "flex-start",
              display: "flex",
            }}
          >
            <div
              style={{
                position: "relative",
                backgroundColor: "lightgray",
                padding: ".5em .5em .5em .5em ",
                right: "3em",
                transform: "rotate(-90deg)",
              }}
            >
              <h2 style={{ color: "#FFFFFF" }}>PHASE 1</h2>
            </div>
          </ParallaxLayer>

          <ParallaxLayer
            offset={2.1}
            speed={0}
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
            onClick={() => parallax.scrollTo(3)}
          >
            <div style={{ bottom: "7em", position: "relative", width: "70%" }}>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                nemo beatae praesentium ad alias dolores possimus fuga quos
                officia minima autem officiis voluptatibus, accusamus porro
                veniam facere, corporis reprehenderit itaque. Lorem ipsum dolor
                sit amet consectetur adipisicing elit. Rerum, nemo beatae
                praesentium ad alias dolores possimus fuga quos officia minima
                autem officiis voluptatibus, accusamus porro veniam facere,
                corporis reprehenderit itaque. Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Rerum, nemo beatae praesentium ad
                alias dolores possimus fuga quos officia minima autem officiis
                voluptatibus, accusamus porro veniam facere, corporis
                reprehenderit itaque. Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Rerum, nemo beatae praesentium ad alias
                dolores possimus fuga quos officia minima autem officiis
                voluptatibus, accusamus porro veniam facere, corporis
                reprehenderit itaque.
              </p>
            </div>
          </ParallaxLayer>

          <ParallaxLayer
            offset={3.1}
            speed={0}
            style={{
              alignItems: "center",
              backgroundImage:
                'url("https://3s81si1s5ygj3mzby34dq6qf-wpengine.netdna-ssl.com/wp-content/uploads/2017/02/ab_sdf8e88883q203.png")',
              // backgroundImage: 'url("../assets/games.jpg)',
              display: "flex",
              justifyContent: "center",
              position: "relative",
            }}
            onClick={() => parallax.scrollTo(3)}
          >
            <h1
              style={{
                color: "#9676AD",
                fontSize: "10em",
                position: "relative",
                bottom: ".5em",
              }}
            >
              CONNECT
            </h1>
          </ParallaxLayer>

          <ParallaxLayer
            offset={3.45}
            speed={0}
            style={{
              alignItems: "flex-start",
              display: "flex",
            }}
          >
            <div
              style={{
                position: "relative",
                backgroundColor: "lightgray",
                padding: ".5em .5em .5em .5em ",
                right: "3em",
                transform: "rotate(-90deg)",
              }}
            >
              <h2 style={{ color: "#FFFFFF" }}>PHASE 2</h2>
            </div>
          </ParallaxLayer>

          <ParallaxLayer
            offset={3.1}
            speed={0}
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
            onClick={() => parallax.scrollTo(3)}
          >
            <div style={{ bottom: "10em", position: "relative", width: "70%" }}>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                nemo beatae praesentium ad alias dolores possimus fuga quos
                officia minima autem officiis voluptatibus, accusamus porro
                veniam facere, corporis reprehenderit itaque. Lorem ipsum dolor
                sit amet consectetur adipisicing elit. Rerum, nemo beatae
                praesentium ad alias dolores possimus fuga quos officia minima
                autem officiis voluptatibus, accusamus porro veniam facere,
                corporis reprehenderit itaque. Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Rerum, nemo beatae praesentium ad
                alias dolores possimus fuga quos officia minima autem officiis
                voluptatibus, accusamus porro veniam facere, corporis
                reprehenderit itaque. Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Rerum, nemo beatae praesentium ad alias
                dolores possimus fuga quos officia minima autem officiis
                voluptatibus, accusamus porro veniam facere, corporis
                reprehenderit itaque.
              </p>
            </div>
          </ParallaxLayer>

          <ParallaxLayer
            offset={3.99}
            speed={0}
            onClick={() => parallax.scrollTo(0)}
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <h1 style={{ color: "#9E9855", fontSize: "10em" }}>CREATE</h1>
          </ParallaxLayer>

          <ParallaxLayer
            offset={5.45}
            speed={0}
            style={{
              alignItems: "flex-start",
              display: "flex",
            }}
          >
            <div
              style={{
                position: "relative",
                backgroundColor: "lightgray",
                padding: ".5em .5em .5em .5em ",
                right: "3em",
                transform: "rotate(-90deg)",
              }}
            >
              <h2 style={{ color: "#FFFFFF" }}>PHASE 3</h2>
            </div>
          </ParallaxLayer>

          <ParallaxLayer
            offset={5.1}
            speed={0}
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
            onClick={() => parallax.scrollTo(3)}
          >
            <div style={{ bottom: "8em", position: "relative", width: "70%" }}>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
                nemo beatae praesentium ad alias dolores possimus fuga quos
                officia minima autem officiis voluptatibus, accusamus porro
                veniam facere, corporis reprehenderit itaque. Lorem ipsum dolor
                sit amet consectetur adipisicing elit. Rerum, nemo beatae
                praesentium ad alias dolores possimus fuga quos officia minima
                autem officiis voluptatibus, accusamus porro veniam facere,
                corporis reprehenderit itaque. Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Rerum, nemo beatae praesentium ad
                alias dolores possimus fuga quos officia minima autem officiis
                voluptatibus, accusamus porro veniam facere, corporis
                reprehenderit itaque. Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Rerum, nemo beatae praesentium ad alias
                dolores possimus fuga quos officia minima autem officiis
                voluptatibus, accusamus porro veniam facere, corporis
                reprehenderit itaque.
              </p>
            </div>
          </ParallaxLayer>
        </Parallax>
      </div>
    );
  } else {
    return <Redirect to="/" />;
  }
};

export default Roadmap;
