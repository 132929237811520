import React from "react";
import { Navbar as NavBar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function Footer() {
  return (
    <NavBar bg="dark" variant="dark" sticky="bottom">
      <div
        style={{
          margin: "auto",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <p
          style={{
            color: "white",
            fontSize: "1em",
            margin: "auto",
          }}
        >
          Copyright 2020 © Coin'd
        </p>
      </div>
    </NavBar>
  );
}

export default Footer;
