import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import fire from "../firebase";
import "firebase/auth";
import ProfileModal from "../components/Modals/ProfileModal";

export default function Profile() {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  const [firstName, setFirstName] = useState("bob");
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [user, setUser] = useState();
  // const [message, setMessage] = useState();
  // const [frontend, setFrontend] = useState(false);
  // const [backend, setBackend] = useState(false);
  // const [writer, setWriter] = useState(false);
  // const [contentCreator, setContentCreator] = useState(false);
  // const [other, setOther] = useState(false);
  // const [otherInput, setOtherInput] = useState();
  const [modalShow, setModalShow] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setModalShow(true);
    fire
      .firestore()
      .collection("developerList")
      // .where("email", "==", "george@mail.com")
      .doc(user.uid)
      // .add({
      //   firstname: firstName,
      //   lastname: lastName,
      //   email,
      //   mobile: mobile ? mobile : "none",
      //   skills: {
      //     frontend,
      //     backend,
      //     writer,
      //     contentcreator: contentCreator,
      //     other: otherInput ? otherInput : "NA",
      //   },
      //   message: message ? message : "none",
      // })
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => console.log(doc.id, " : ", doc.data()));

        // document.getElementById("first-name").value = "";
        // document.getElementById("last-name").value = "";
        // document.getElementById("email").value = "";
        // document.getElementById("mobile").value = "";
        // document.getElementById("front").checked = false;
        // document.getElementById("back").checked = false;
        // document.getElementById("writer").checked = false;
        // document.getElementById("content").checked = false;
        // document.getElementById("other").checked = false;
        // document.getElementById("other-input").value = "";
        // document.getElementById("message-box").value = "";
      })
      .catch((err) => console.log(err));
  };

  const authListener = () => {
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser("");
      }
    });
  };
  useEffect(() => {
    authListener();
  }, []);

  console.log("profile user: ", user);
  console.log("user 2: ", fire.auth().currentUser);
  return (
    <div className="contact-container">
      <div className="contact" style={{ marginTop: "5em" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "6em",
          }}
        >
          <svg
            id="logo2"
            width="464.648"
            height="146.097"
            viewBox="0 0 464.648 146.097"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="svgGroup"
              strokeLinecap="round"
              fillRule="evenodd"
              fontSize="9pt"
              stroke="#000"
              strokeWidth="0"
              fill="none"
            >
              <path
                d="M 375.195 1.955 L 401.465 63.674 L 427.637 1.955 L 464.648 1.955 L 418.848 93.068 L 418.848 144.142 L 384.082 144.142 L 384.082 93.068 L 338.379 1.955 L 375.195 1.955 Z"
                fill="#5FB88C"
              />
              <path
                d="M 302.539 1.955 L 302.539 117.775 L 362.207 117.775 L 362.207 144.142 L 268.262 144.142 L 268.262 1.955 L 302.539 1.955 Z"
                fill="#B85C39"
              />
              <path
                d="M 56.152 95.9 L 34.277 95.9 L 34.277 144.142 L 0 144.142 L 0 1.955 L 56.738 1.955 A 75.335 75.335 0 0 1 72.107 3.456 A 58.928 58.928 0 0 1 85.596 7.961 A 47.058 47.058 0 0 1 99.488 17.885 A 43.866 43.866 0 0 1 105.029 25.051 Q 111.914 36.135 111.914 50.197 A 46.855 46.855 0 0 1 109.361 65.991 A 39.919 39.919 0 0 1 97.021 83.449 A 50.259 50.259 0 0 1 78.232 93.119 Q 68.43 95.9 56.152 95.9 Z"
                fill="#9E9855"
              />
              <path
                d="M 250.586 69.435 L 250.586 75.978 A 95.465 95.465 0 0 1 248.657 95.583 A 74.882 74.882 0 0 1 242.871 112.795 A 59.529 59.529 0 0 1 230.506 130.112 A 55.118 55.118 0 0 1 220.947 137.453 A 59.709 59.709 0 0 1 193.605 145.925 A 73.041 73.041 0 0 1 188.574 146.096 Q 170.41 146.096 156.348 137.746 A 56.572 56.572 0 0 1 135.174 115.309 A 68.056 68.056 0 0 1 134.424 113.869 A 75.729 75.729 0 0 1 127.233 90.932 A 96.566 96.566 0 0 1 126.172 78.224 L 126.172 70.217 A 96.718 96.718 0 0 1 128.029 50.839 A 74.833 74.833 0 0 1 133.838 33.351 A 58.623 58.623 0 0 1 147.327 15.008 A 55.737 55.737 0 0 1 155.811 8.693 A 60.141 60.141 0 0 1 183.321 0.174 A 73.433 73.433 0 0 1 188.379 0.002 A 64.863 64.863 0 0 1 208.209 2.952 A 57.514 57.514 0 0 1 220.605 8.596 A 57.396 57.396 0 0 1 241.587 31.043 A 69.291 69.291 0 0 1 242.627 33.058 A 76.002 76.002 0 0 1 249.686 56.477 A 97.229 97.229 0 0 1 250.586 69.435 Z "
                fill="#6063AD"
              />
              <path
                d="M 215.723 76.564 L 215.723 70.021 A 101.93 101.93 0 0 0 215.099 58.338 Q 213.621 45.568 208.643 37.844 Q 202.081 27.662 190.277 26.916 A 30.107 30.107 0 0 0 188.379 26.857 A 22.724 22.724 0 0 0 166.975 39.264 Q 161.926 48.474 161.175 64.521 A 114.055 114.055 0 0 0 161.133 65.529 L 161.035 75.978 A 101.294 101.294 0 0 0 161.695 87.956 Q 163.175 100.342 167.969 108.107 A 23.671 23.671 0 0 0 175.287 115.76 Q 180.857 119.338 188.574 119.338 Q 200.608 119.338 207.528 109.865 A 29.73 29.73 0 0 0 208.594 108.303 A 38.659 38.659 0 0 0 212.892 98.667 Q 215.662 89.504 215.723 76.564 Z"
                fill="#000"
              />
              <path
                d="M 34.277 28.42 L 34.277 69.435 L 56.738 69.435 A 31.968 31.968 0 0 0 62.501 68.949 Q 68.332 67.879 71.924 64.455 Q 77.148 59.474 77.148 50.392 A 30.049 30.049 0 0 0 76.455 43.75 Q 75.286 38.589 72.147 34.897 A 19.219 19.219 0 0 0 71.777 34.474 A 17.946 17.946 0 0 0 61.001 28.743 A 25.616 25.616 0 0 0 57.129 28.42 L 34.277 28.42 Z"
                fill="#000"
                vectorEffect="non-scaling-stroke"
              />
            </g>
          </svg>
        </div>
        <h1>UPDATE PROFILE</h1>

        <ProfileModal show={modalShow} onHide={() => setModalShow(false)} />

        <Form
          onSubmit={handleSubmit}
          style={{
            backgroundColor: "lightGray",
            borderRadius: "0.5em",
            marginTop: "5em",
            padding: "2em 4em 0 4em",
          }}
        >
          <Form.Group controlId="formBasicText">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              id="first-name"
              type="text"
              placeholder={firstName}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formBasicText">
            <Form.Label> Last Name</Form.Label>
            <Form.Control
              id="last-name"
              type="text"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              id="email"
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="formBasicText">
            <Form.Label>Mobile</Form.Label>
            <Form.Control
              id="mobile"
              type="tel"
              placeholder="xxx-xxx-xxxx"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
          </Form.Group>

          <div>
            <Button
              className="profile-button"
              type="submit"
              style={{
                margin: "0 0 5% 40%",
              }}
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
