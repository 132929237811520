import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Nav, Navbar as NavBar, NavDropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import "firebase/auth";
import "firebase/storage";
import fire from "../../firebase";

function Navbar() {
  // const [click, setClick] = useState(false); = useState()
  // const [button, setButton] = useState(true);
  const [user, setUser] = useState();
  const [url, setUrl] = useState();

  // const handleClick = () => setClick(!click);
  // const closeMobileMenu = () => setClick(false);

  const handleLogout = () => {
    fire.auth().signOut();
  };

  // const showButton = () => {
  //   if (window.innerWidth <= 960) {
  //     setButton(false);
  //   } else {
  //     setButton(true);
  //   }
  // };

  const authListener = () => {
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser("");
      }
    });
  };
  useEffect(() => {
    // showButton();
    authListener();
  }, []);

  // window.addEventListener("resize", showButton);

  let file;

  const getFile = () => {
    // e.preventDefault();
    window.scrollTo(0, 0);
    setTimeout(() => {
      setUrl();
    }, 15000);
    fire
      .storage()
      .ref(`files/${file}`)
      .getDownloadURL()
      .then((url) => {
        setUrl(url);
      })
      .catch(function (error) {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist
            break;

          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;

          case "storage/canceled":
            // User canceled the upload
            break;

          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            break;
          default:
        }
        console.log(error.code);
      });
  };

  const resetUrl = () => {
    setTimeout(() => {
      setUrl();
    }, 1000);
  };

  // console.log("NAV USER: ", fire.auth().currentUser);

  document.getElementById("resources");

  return (
    <>
      <NavBar bg="dark" variant="dark" sticky="top">
        <NavBar.Brand style={{ fontSize: "2em", marginLeft: "2em" }} href="/">
          POLY
        </NavBar.Brand>
        <Nav
          className="mr-auto"
          style={{ fontSize: "1.2em", marginLeft: "45%" }}
        >
          {user ? (
            <NavDropdown
              id="resources"
              title="Resources"
              style={{ margin: "0 1em" }}
            >
              <NavDropdown.Item onClick={() => getFile((file = "POLY.pdf"))}>
                POLY Brochure
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => getFile((file = "dummy.pdf"))}>
                Style Guide
              </NavDropdown.Item>
              <NavDropdown.Item href="/details" disabled>
                Roadmap (detailed)
              </NavDropdown.Item>
              <NavDropdown.Item href="/demo">App Demo</NavDropdown.Item>
            </NavDropdown>
          ) : (
            <NavDropdown
              disabled
              id="resources"
              title="Resources"
              style={{ margin: "0 1em" }}
            >
              <NavDropdown.Item disabled>Style Guide</NavDropdown.Item>
              <NavDropdown.Item disabled>Roadmap (detailed)</NavDropdown.Item>
              <NavDropdown.Item href="/demo" disabled>
                App Demo
              </NavDropdown.Item>
            </NavDropdown>
          )}

          {user ? (
            <Nav.Link href="/board">Message Board</Nav.Link>
          ) : (
            <Nav.Link href="/board" disabled>
              Message Board
            </Nav.Link>
          )}

          <Nav.Link href="/support" style={{ margin: "0 1em" }}>
            Support
          </Nav.Link>

          {user ? (
            <Nav.Link href="/profile">
              <FontAwesomeIcon
                icon={faUser}
                style={{
                  marginLeft: "95%",
                  fontSize: "24px",
                }}
                title="Sign in to access this content"
              />
            </Nav.Link>
          ) : null}
        </Nav>

        <div style={{ marginRight: "5em" }}>
          {user ? (
            <Button
              className="signout"
              style={{ width: "100px" }}
              onClick={handleLogout}
            >
              SIGN OUT
            </Button>
          ) : (
            <Link to="/signin">
              <Button className="signin" style={{ width: "100px" }}>
                SIGN IN
              </Button>
            </Link>
          )}
        </div>
      </NavBar>
      <div
        className="blinking"
        style={{
          backgroundColor: "#000",
          border: url ? "#9e9855 1px solid" : null,
          borderRadius: "10px",
          padding: "1em 1em .25em 1em",
          position: "absolute",
          left: "42.5%",
          top: "10%",
        }}
      >
        {url ? (
          <a
            href={url}
            onClick={resetUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              backgroundColor: "#000",
              color: "#fff",
              textDecoration: "none",
            }}
          >
            <h6>CLICK TO OPEN / DOWNLOAD FILE </h6>
          </a>
        ) : null}
      </div>
    </>
  );
}

export default Navbar;
