import * as firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
  // apiKey: "AIzaSyBPsbhT5ihI4DGpDP8hIDEtkXMd0C5ovok",
  apiKey: process.env.REACT_APP_FIREBASE_API,
  authDomain: "poly-welcome.firebaseapp.com",
  databaseURL: "https://poly-welcome.firebaseio.com",
  projectId: "poly-welcome",
  storageBucket: "poly-welcome.appspot.com",
  messagingSenderId: "550990970681",
  appId: "1:550990970681:web:e37163644276a412e272e8",
};

const fire = firebase.initializeApp(firebaseConfig);

export default fire;
