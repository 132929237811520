import React, { useEffect, useState } from "react";
import { Card, CardDeck, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import "firebase/auth";
import fire from "../../firebase";

function Cards() {
  const [user, setUser] = useState();
  const authListener = () => {
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser("");
      }
    });
  };

  useEffect(() => {
    authListener();
  }, []);

  console.log("cards user:", user);

  return (
    <div className="cards">
      <div className="cards-container">
        <CardDeck>
          <Card style={{ border: "#7f7f7f 1px solid" }}>
            <Card.Img variant="top" src={require("../../assets/mobile.jpg")} />
            <hr
              style={{
                color: "#b85c39",
                border: "5px solid",
                position: "relative",
                bottom: "1em",
              }}
            />
            <Card.Body>
              <Card.Title className="link">POLY: An Introduction</Card.Title>
              <Card.Text>
                Learn more about the community-based experience platform.
              </Card.Text>
              <Link to="/intro">
                <Button
                  className="intro-button"
                  style={{ position: "relative", top: "2em" }}
                >
                  GO
                </Button>
              </Link>
            </Card.Body>
          </Card>

          <Card style={{ border: "#7f7f7f 1px solid" }}>
            <Card.Img variant="top" src={require("../../assets/roadmap.jpg")} />
            <hr
              style={{
                color: "#b85c39",
                border: "5px solid",
                position: "relative",
                bottom: "1em",
              }}
            />
            <Card.Body>
              <Card.Title>Roadmap</Card.Title>
              <Card.Text>
                Explore the 3 development phases for the POLY app.
              </Card.Text>
              <Link to="/roadmap">
                <Button
                  id="roadmap"
                  className="roadmap-button"
                  style={{ position: "relative", top: "2em" }}
                >
                  GO
                </Button>
              </Link>
              {!user ? (
                <FontAwesomeIcon
                  icon={faLock}
                  style={{
                    marginLeft: "95%",
                    fontSize: "24px",
                  }}
                  title="Sign in to access this content"
                />
              ) : null}
            </Card.Body>
          </Card>

          <Card style={{ border: "#7f7f7f 1px solid" }}>
            <Card.Img
              variant="top"
              src={require("../../assets/questions.jpg")}
            />
            <hr
              style={{
                color: "#b85c39",
                border: "5px solid",
                position: "relative",
                bottom: "1em",
              }}
            />
            <Card.Body>
              <Card.Title>FAQs</Card.Title>
              <Card.Text>Still have questions? Find answers.</Card.Text>
              <div style={{ height: "2.5em" }}></div>
              <Link to="/faqs">
                <Button
                  className="faq-button"
                  style={{ position: "relative", top: "1em" }}
                >
                  GO
                </Button>
              </Link>
            </Card.Body>
          </Card>
        </CardDeck>
        <div style={{ marginBottom: "2em", marginTop: "8em" }}>
          <h2>Sound like fun?</h2>
          <h2>What are you waiting for?</h2>
        </div>
        <Link to="/contact">
          <Button className="join-button">JOIN THE TEAM</Button>
        </Link>
      </div>
    </div>
  );
}

export default Cards;
